<template>
  <div id="register">
    <noOpen :list="list"></noOpen>
    <copyrightprotect></copyrightprotect>
  </div>
</template>

<script>
import noOpen from '@/components/copyrightprotect/noOpen' //顶部开通
import copyrightprotect from '@/components/copyrightprotect/index' //轮播图
export default {
  name: 'register',
  data() {
    return {
      list: {
        status: 3,
        src: require('@/assets/img/1.3.8/icon_bqdj.png'),
        title: '叮叮开课独家专利盗版追溯系统',
        describe:
          '委托向国家版权局提交作品认证登记，保护作品免受抄袭侵权的困扰，是解决版权纠纷和申请司法保护的重要凭证。',
        button_name: '申请追踪',
        money: '',
        strike: '',
        function_tu: require('@/assets/img/1.3.8/img_bqdjjst.jpg'),
        tip: ''
      }
    }
  },
  components: {
    copyrightprotect,
    noOpen
  }
}
</script>
